<!--
 * @Author: Neko
 * @Date: 2021-01-07 15:53:03
 * @LastEditTime: 2021-03-25 20:01:11
 * @Description: 注册和忘记密码页面，两个功能用同一个页面
 * @LastEditors: Neko
-->
<template>
  <div class="register__container">
    <div class="register__wrap">

      <div class="register__container-wrap">
        <div class="register__container-header">
          <div class="header">
            <div class="header__wrap">
              <h2 class="header__title">
                <span class="title">{{ $t('login.forget_password') }}</span>
              </h2>
            </div>
          </div>
        </div>

        <el-form ref="passwordFormRef" class="account__form" label-position="left" label-width="120px" :model="passwordForm" :rules="passwordFormRuler">
          <el-form-item :label="$t('login.email_address') + '：'" prop="email">
            <el-input v-model="passwordForm.email" />
          </el-form-item>

          <el-form-item :label="$t('login.verify_code') + '：'" prop="validateCode">
            <el-input v-model="passwordForm.validateCode">
              <template slot="append">
                <SendCode class="send-code" :before-start="onBeforeStartEmail" @start="onSendCode" />
              </template>
            </el-input>
          </el-form-item>

          <el-form-item ref="passwordFormItemRef" :label="$t('login.new_password') + '：'" prop="password">
            <el-input v-model="passwordForm.password" type="password" />
          </el-form-item>

          <el-form-item ref="repasswordFormItemRef" :label="$t('login.repeat_password')" prop="rePassword">
            <el-input v-model="passwordForm.rePassword" type="password" />
          </el-form-item>
        </el-form>

        <div class="account__button">
          <el-button class="button" type="primary" @click="onClickSavePasswordBtn">{{ $t('login.submit') }}</el-button>
          <router-link class="router-link" :to="{ name: 'UserLogin' }">{{ $t('login.return_to_login') }}</router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SendCode from '@/components/Common/SendCode.vue'
import { computed, reactive, ref, toRefs, watch } from '@vue/composition-api'
import { Message } from 'element-ui'

import { useRequest } from '@/utils/request.js'
import { validateMobile } from '@/utils'
import { Base64 } from '@/utils/base64'

export default {
  name: 'UserRegister',

  components: {
    SendCode
  },

  setup(_, { root }) {
    const { $route, $jst } = root

    const blur = { required: true, message: $jst('login.blur_info'), trigger: 'blur' }

    const passwordFormRef = ref(null)
    const passwordFormItemRef = ref(null)
    const repasswordFormItemRef = ref(null)

    const checkRepeatValidator = (_, value, callback) => {
      if (data.passwordForm.password === data.passwordForm.rePassword) {
        passwordFormItemRef.value.clearValidate()
        repasswordFormItemRef.value.clearValidate()
        return callback()
      }

      if (data.passwordForm.password !== data.passwordForm.rePassword) {
        return callback(new Error($jst('login.repeat_password_incorrect')))
      }

      callback()
    }

    const data = reactive({
      type: $route.meta.type,
      formData: {
        mobile: '',
        validateCode: '',
        password: '',
        rePassword: ''
      },

      ruler: {
        mobile: [blur, { validator: validateMobile, trigger: 'blur' }],
        validateCode: blur,
        password: blur,
        rePassword: blur
      },

      passwordForm: {
        password: '',
        rePassword: '',
        validateCode: '',
        email: ''
      },

      passwordFormRuler: computed(() => ({
        email: [{ required: true, message: $jst('login.blur_info'), trigger: 'blur' }, { type: 'email', trigger: 'blur' }],
        password: [{ required: true, message: $jst('login.blur_info'), trigger: 'blur' }, { validator: checkRepeatValidator, trigger: 'blur' }],
        rePassword: [{ required: true, message: $jst('login.blur_info'), trigger: 'blur' }, { validator: checkRepeatValidator, trigger: 'blur' }],
        validateCode: { required: true, message: $jst('login.blur_info'), trigger: 'blur' }
      }))
    })

    const { fetch: sendEmailCodeFetch } = useRequest('account/sendEmailCodeAction', {
      data: computed(() => ({
        email: data.passwordForm.email
      })),

      immediate: false
    })

    const onBeforeStartEmail = _ => {
      let result = false
      passwordFormRef.value.validateField('email', res => {
        if (res) {
          Message.error($jst('login.email_incorrect'))
          result = false
        } else {
          data.validateType = '2'
          result = true
        }
      })

      return result
    }

    const onSendCode = () => {
      sendEmailCodeFetch()
    }

    const { data: handleSavePasswordResult, fetch: handleSavePasswordFetch } = useRequest('account/handleSavePasswordAction', {
      data: computed(() => Object.assign({}, data.passwordForm, {
        password: Base64.encode(data.passwordForm.password),
        rePassword: Base64.encode(data.passwordForm.rePassword)
      })),
      immediate: false,
      isCustom: true
    })

    const onClickSavePasswordBtn = _ => {
      passwordFormRef.value.validate(isValid => {
        if (isValid) {
          handleSavePasswordFetch()
        }
      })
    }

    watch(() => handleSavePasswordResult.value, res => {
      if (res.code === 200) {
        Message.success($jst('login.edit_successful'))
        data.passwordForm = {
          password: '',
          rePassword: '',
          validateCode: '',
          email: computed(() => data.userData.email)
        }
        passwordFormRef.value.resetFields()
      }
    })

    return {
      passwordFormRef,
      ...toRefs(data),
      passwordFormItemRef,
      repasswordFormItemRef,

      onSendCode,
      onBeforeStartEmail,
      onClickSavePasswordBtn
    }
  }
}
</script>

<style lang="scss" scoped>
@import './style/common.scss';

.register__container {
  padding-bottom: 50px;

  &.forgot {
    padding-bottom: 19px;
  }

  .register__title {
    margin-bottom: 22px;
    font-size: 18px;
    letter-spacing: 3px;
    text-align: center;
    color: #444444;
  }

  .form {
    @include form;
  }

  .register__operation {
    .button {
      @include button;
    }

    .router-link {
      display: block;
      margin-top: 17px;
      font-size: 14px;
      text-align: center;
      color: #0049AC;
    }
  }

  ::v-deep .el-input-group__append {
    border: none;
    background: transparent;
  }

  .register__container-wrap {
    // min-width: 1200px;
    width: 96.9%;
    max-width: 1600px;
    margin: 0 auto;
    box-shadow: 0px 17px 102px 15px rgba(232, 232, 234, 0.95);

    .register__container-header {
      .header {
        position: relative;
        z-index: 2;
        background: #fff;

        .header__wrap {
          position: relative;
          z-index: 2;
          background: #fff;
        }

        .header__title {
          // width: 1200px;
          padding: 0 20px;
          margin: 0 auto;
          letter-spacing: 2px;
          font-size: 18px;

          .title {
            display: inline-block;
            padding: 33px 0 26px;
            border-bottom: 2px solid #0049AC;
            color: #0D5CAB;
          }

        }

      }
    }
  }

  .account__form {
    width: 600px;
    padding: 40px 40px;
    margin: 0 auto;
  }

  .account__button {
    padding: 20px 0;
    text-align: center;

    .button {
      border-radius: 25px;
    }

    .router-link {
      margin-left: 20px;
      color: #0049AC;
      text-decoration: underline;
    }
  }
}
</style>
